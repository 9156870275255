* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
@include respond(normal){
  font-size: 14px;
}
@include respond(narrow){
  font-size: 12px;
}
@include respond(min-mobile){
  font-size: 11px;
}

}
::selection{
  background-color:$color-black-dark ;
  color:$color-white;
}
body {
 font-family: 'Montserrat', sans-serif;
 
  }

input {
  font-family: inherit;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

a {
  text-decoration: none;
}

.back-drop-layer {
  position: fixed;
  z-index: 2;

top: 0;
right: 0;
left: 0;
bottom: 0;
  background-color: rgba(0, 0, 0, 0.404);
}

