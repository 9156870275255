
        @import "./src/styles/setup";
        .logo {
    
                &-txt{
                font-family: 'Rubik', sans-serif;
                margin-right: 3px;
                  span{
                    font-size: 9px;
                    
                    color:rgba($color-light-white,0.5) ;
                  }
                  letter-spacing: 1px;
                }
                &-img{
                  width: 50px;
                height: 50px;
                }
        }