
        @import "./src/styles/setup";
        .title-page {
                .header-primary {
                        background-color: $color-black-light;
                        transform: skewX(352deg);
                        text-transform: uppercase;
                        letter-spacing: 4px;
                        color: $color-white;
                        width: fit-content;
                        padding: 6px 21px;
                        font-size: rem(20px);
                     
                    }
                .line{
                        display: inline-block;
                        width: rem(100px);
                        background-color: $color-black-dark-2;
                        height: 2px;
                        margin: 0 5px;
                      }
        }