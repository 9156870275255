
        @import "./src/styles/setup";
        .back-drop-layer {
                position: fixed;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                z-index: 5;
                background-color: black;
                opacity: 0.6;
        }