
        @import "./src/styles/setup";
        .footer {
                background-color: $color-black-dark-2;
                height: 130px;
                padding: 20px 0;
                >*{
                        margin-bottom: rem(10px);
                }
                small{
                        color: $color-light-white;
                        font-weight: 300;
                        margin-top: 7px;
                        letter-spacing: 2px;
                        font-size: 10px;
                        font-family: 'Assistant', sans-serif;
                }
                .logo{
                       
                        width: 40px;
                }
        }