
/* Flex Helpers! */

.flex {
  display: flex;
  &.space-around {
    justify-content: space-around;
  }
  &.center-center{
    justify-content: center;
    align-items: center;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.align-center {
    align-items: center;
  }
  &.space-evenly {
    justify-content:space-evenly;
  }
  &.align-self-end {
    align-self: flex-end;
  }
  &.column {
    flex-direction: column;
  }
  &.flex-gap{
    gap:rem(10px);
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.justify-center {
    justify-content: center;
  }

  > .grow {
    flex-grow: 1;
  }
}


//MARGINS
.text-center{
  text-align: center;
}
.margin-center{
  margin: 0 auto;
}
// POSITIONS
.relative {
  position: relative;
}
.absolute{
  position: absolute;
}
.margin{
  &-top{
    margin-top: 1rem;
  }
  &-bottom{
    margin-bottom: 1rem;
  }
  &-left{
    margin-left: 1rem;
  }
  &-right{
    margin-right: 1rem;
  }
  &-all{
    margin: rem(5px);
  }
}
.padding{
  &-top{
    padding-top: 1rem;
  }
  &-bottom{
    padding-bottom: 1rem;
  }
  &-left{
    padding-left: 1rem;
  }
  &-right{
    padding-right: 1rem;
  }
  &-all{
    padding:1rem;
  }
}


/* Ul */

.clean-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.button-control {
  .btn-cta {
    text-transform: capitalize;
          padding: rem(11px) rem(15px);
          border: 0;
          border-radius: 15px;
          color: $color-white;
          background-color: $color-black-dark-2;
          border: 1px solid;
          font-family: 'Rubik', sans-serif;
          font-weight: 100;
          transition: all .5s ease-in-out;

          i {
                  margin-left: 10px;
          }

          &.arrow::after {
                  content: "\2192";
                  padding-left: 10px;
          }

          &:hover {
                  &.arrow::after {
                          padding-left: rem(18px);

                  }

                  i {
                          margin-left: rem(18px);
                  }

                  color: $color-black-dark-2;
                  background-color: $color-white;
                  border-color: $color-light-white;
                  transform: translateY(-5px);
          }
  }

}

