
        @import "./src/styles/setup";
        .imgs {
                margin-bottom: rem(50px);
                .img-preview{
                        img{
                                margin-right: rem(-17px);
                                width: rem(100px);
                                border-radius: 50%;
                                height: rem(100px);
                                object-fit: cover;
                                object-position: top;
                                border: 3px solid $color-white;
                                transition: all .3s ease;
                              position: relative;
                              z-index: 2;
                              cursor: pointer;
                                &:hover{
                                      z-index: 3;
                                        transform: scale(1.5);
                                    
                                }
                        }
                }
        }