//SETUP:

@import "setup";

//BASE:
@import "./basics/fonts";
@import "./basics/base";
@import "./basics/helpers";
@import "./basics/layout";




.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  main {
    flex-grow: 1;
    background-color: inherit;
    >*:first-child{
      // height: 100%;
      min-height:calc(100vh - 60px - 130px);
    }
  }
}

