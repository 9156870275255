
        @import "./src/styles/setup";
        .project-preview {
                cursor: pointer;
                height: rem(410px);
                width: rem(300px);
                margin: rem(10px);
                border: 1px solid rgba($color-black-dark,0.2);
                padding: rem(15px) rem(15px);
                border-radius: rem(25px);
                box-shadow: 0 0px rem(8px) #00000029;
                position: relative;
                transition: transform .3s;
                @include respond(max-narrow){
                 &::after{
                         content: "";
                         position: absolute;
                         top: 0;
                         height: 100%;
                         width: 100%;
                         background-color: rgba(rgb(23, 21, 21),0.86);
                        border-radius: rem(25px);

                 }
                }
                @include respond(min-narrow){
                        width: 90%;
                        height: rem(510px);
                        background-color: #ececec7a;
                }
                &:hover{
                        transform: translateY(-5px);
                }
                &.active::after{
                        content: "";
                        height: 100%;
                        width: 100%;
                        background-color: rgba(black,0.5);
                        z-index: 2;
                        position: absolute;
                        border-radius: rem(25px);
                        top: 0;
                }


                .img-container{
                        height: rem(200px);
                        margin-bottom: rem(6px);
                }
                .project-img{
                        height: rem(120px);
                        object-fit: cover;
                        object-position: top;
                        border-radius: rem(18px);
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                        border: 1px solid #00000021;
                        width: 100%;
                        position: absolute;
                        height: rem(200px);
                        top: 0;
                        left: 0;

                }
                .project{
                        &-content{
                                @include respond(max-narrow){
                                        position: relative;
                                        z-index: 3;
                                }
                                @include respond(min-narrow){
                                        height: 60%;
                                        justify-content: space-around;
                                }
                                >*:not(:last-child){
                                        margin-bottom: 16px;
                                        @include respond(min-narrow){
                                                margin-bottom: 0;
                                        }
                                }
                        }
                        &-title{
                                color:$color-black;
                                @include respond(max-narrow){
                                        font-size: 20px;
                                        color:$color-white;
                                }
                        }
                        &-description{
                                color:$color-light-grey;
                                @include respond(max-narrow){
                                        color:$color-white;
                                        font-size: 15px;
                                }
                        }
                        &-skills{
                                font-size: rem(13px);
                                letter-spacing: 1px;
                                color:$color-light-grey-3;
                                text-transform: uppercase;
                                   color:$color-light-grey;
                                   @include respond(max-narrow){
                                        color:rgba($color-white,0.8);
                                        font-size: 13px;
                                }
                        }
                }
                .button-control{
                        position: absolute;
                        top: 80%;
                        left:50%;
                        transform: translate(-50%,-50%);
                        z-index: 9;
                        width: 100%;
                        @include respond(max-narrow){
                                margin-top: 5px;
                                position: static;
                                top: none;
                                left:none;
                                transform:translate(0,0);
                                flex-direction: column;
                        }
                        button{
                                transition: all .3s ease-in-out;
                        }
                        .btn-project{
                                padding: 10px 13px;
                                text-transform: capitalize;
                                color: white;
                                font-family: 'Rubik', sans-serif;
                                background-color: $color-black-dark-2;
                        @include respond(max-narrow){
                                margin-right: 0 !important;
                                margin-bottom: 15px;
                                background-color: $color-white;
                               color: $color-black-dark-2;
                        }
                                &::after{
                                        content: "\2192";
                                        padding-left: 10px;
                                        transition: padding .3s;
                                }
                                &:hover{
                                        &::after{padding-left: 15px;}
                                        transform:translateX(5px) ;

                                }
                        }
                        .btn-git{
                                background-color: $color-black-dark-2;
                                padding: 10px;
                                border-radius: 50%;
                                
                                i{
                                        color: $color-white;
                                        font-size: rem(16px);
                                }
                                &:hover{
                                        transform:translateX(5px) ;
                                }
                                @include respond(max-narrow){
                                  
                                        background-color: $color-white;
                                        i{

                                                color: $color-black-dark-2;
                                        }
                                }
                        }
                }

        }