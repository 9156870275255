
        @import "./src/styles/setup";
        .social-media {
                .social-link{
                        font-size: rem(20px);
                        color: $color-light-white;
                        transition: all .3s ease-out;
                        &:hover{
                                color:rgba($color-white,0.8);
                                transform: translateY(-5px) scale(1.2);
                        }
                        &:not(:last-child){
                                margin-right: 9px;
                        }

                }
        }