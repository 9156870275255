@import "./src/styles/setup";

.about {
        padding-top: rem(50px);
        padding-bottom: rem(50px);
        animation: fadeIn 2s ease-in;
        .img-container{
                box-sizing:content-box ;
                height: rem(200px);

                border: 1px solid black;
                @include respond(mid-normal){
                        flex-direction: column-reverse;
                }
        }
        .img-about {
             
                height: inherit;
                transition: all .3s;
      
        }

        &-p {
                line-height: 1.6;
                color: $color-dark-grey;
                overflow-wrap: break-word;
                margin-bottom: rem(10px);
                font-size: 14px;
                letter-spacing: 1px;
                @include respond(max-narrow){
                        font-size: 12px;

                }
        }

        &-container {
                margin-bottom: rem(30px);
                @include respond(mid-normal){
                        flex-direction: column-reverse;
                }
        }
        .about-content{
                max-width: rem(600px);
                margin-right:rem(35px);
                
                padding-top: rem(30px);
                animation: fadeIn 2s ease-in;
                @include respond(narrow){
                        margin-right:0;  
                }
                .social-media{
                        margin-top: 5px;
                        @include flex-center;
                        a{

                                color: $color-black-dark;
                        }
                }

        }

        .icon-container {
                >*:not(:last-child){
                        margin-right: 10px;
                        margin-bottom: 10px;
                }
                span {
                        border-radius: 50%;
                        background-color: $color-black-dark;
                        width: rem(60px);
                        height: rem(60px);
                        transition: all .3s;
                        padding: rem(5px) rem(5px);

                        &:hover {
                                background-color: rgba($color-black-dark, 0.9);
                                transform: translateY(5px);
                        }
                }

                i {
                        font-size: rem(30px);
                        color: $color-white;
                        transition: color .3s;
                        cursor: pointer;

                        &:hover {
                                color: $color-light-white;
                        }
                }
        }
}