// LAYOUT BREAKPOINTS
$break-xs-mobile: 350px;
$break-max-mobile: 380px;
$break-mobile: 400px;
$break-mobile-min: 450px;
$break-min-narrow:500px;
$break-max-narrow: 550px;
$break-narrow: 750px;
$break-mid-normal: 850px;
$break-normal: 950px;
$break-max-normal: 1050px;
$break-wide: 1300px;
//COLORS
$color-black:rgb(17,17,17);
$color-white:#ffffff;
$color-black-light:#1f2020;
$color-light-grey:#636363;
$color-light-grey-2:#3C3D3C;
$color-light-grey-3:#6B6B6B;
$color-light-white:#FAFAFA;
$color-black-dark:#333333;
$color-black-dark-2: #121212;
$color-dark-grey: #222326;

