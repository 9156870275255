/* Media queries Breakpoint mixins */

@mixin respond($breakpoint) {
   //$break-xs-mobile: 350px;
   @if $breakpoint==xs-mobile {
    @media only screen and (max-width: $break-xs-mobile) {
      @content;
    }
  }
  // $mobile: 380px;
  @if $breakpoint==max-mobile {
    @media only screen and (max-width: $break-max-mobile) {
      @content;
    }
  }
  // $mobile: 400px;
  @if $breakpoint==mobile {
    @media only screen and (max-width: $break-mobile) {
      @content;
    }
  }
  // $mobile: 450px;
  @if $breakpoint==min-mobile {
    @media only screen and (max-width: $break-mobile-min) {
      @content;
    }
  }
   // $max-narrow: 500px;
  @if $breakpoint==min-narrow {
    @media only screen and (max-width: $break-max-narrow) {
      @content;
    }
  }
  // $max-narrow: 550px;

  @if $breakpoint==max-narrow {
    @media only screen and (max-width: $break-max-narrow) {
      @content;
    }
  }
  // $narrow: 750px;

  @if $breakpoint==narrow {
    @media only screen and (max-width: $break-narrow) {
      @content;
    }
  }
    // $break-mid-normal: 850px;
    @if $breakpoint==mid-normal {
      @media only screen and (max-width: $break-mid-normal) {
        @content;
      }
    }
  // $break-normal: 950px;

  @if $breakpoint==normal {
    @media only screen and (max-width: $break-normal) {
      @content;
    }
  }
  // $break-max-normal:1050px;

  @if $breakpoint==max-normal {
    @media only screen and (max-width: $break-max-normal) {
      @content;
    }
  }

  // $break-wide: 1300px;
  @if $breakpoint==wide {
    @media only screen and (max-width: $break-wide) {
      @content;
    }
  }
}

@mixin for-mobile-layout {
  // $break-max-narrow: 400px;

  @media (max-width: $break-mobile) {
    @content;
  }
}

@mixin for-max-narrow-layout {
  // $break-max-narrow: 550px;

  @media (max-width: $break-max-narrow) {
    @content;
  }
}

@mixin for-narrow-layout {
  // $break-narrow: 750px;

  @media (max-width: $break-narrow) {
    @content;
  }
}

@mixin for-normal-layout {
  // $break-norml: 950px;
  @media (max-width: $break-normal) {
    @content;
  }
}

@mixin for-wide-layout {
  // $break-wide: 1300px;
  @media (max-width: $break-wide) {
    @content;
  }
}
@mixin flex-center{
  
  align-items: center;
  justify-content: center;
}
/* Media  Mixins */

//aspect ratio mixin
@mixin aspect-ratio($width, $height) {
  position: relative;
  overflow: hidden;
  &:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  > img {
    height: 100%;
    object-fit: cover;
  }
}

// Mixin for removing hover efects on iphone screen
@mixin hover-supported {
  @media not all and (pointer: coarse) {
    &:hover {
      @content;
    }
  }
}
