@keyframes slide-in-out {
  0% {
      transform: translateY(150%);
  }

  12% {
      transform: translateY(0);
      opacity: 1;
  }

  90% {
      transform: translateY(0);
      opacity: 1;
  }

  100% {
      transform: translateY(150%);
      opacity: 0;
  }
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }

  to {
      opacity: 1;
  }
}

@keyframes fadeout {
  from {
      opacity: 1;
  }

  to {
      opacity: 0;
  }
}

@keyframes scaleIn {
  from {
      transform: scale(0.6);
  }

  to {
      transform: scale(1);
  }
}

@keyframes scaleOut {
  from {
      transform: scale(1);
  }

  to {
      transform: scale(0.6);
  }
}

@keyframes scaleinsoft {
  from {
      transform: scale(0.8);
  }

  to {
      transform: scale(1);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}