@import "./src/styles/setup";

.home-page {
        background-color: rgb(21, 20, 20);
        color: $color-white;
        min-height:calc(100vh - 60px - 130px);
        .bg-particles {
                height:calc(100vh - 60px - 130px);
                width: 100%;
                position: absolute;
                z-index: 1;
        }

     

        .hero {
                padding-top: 100px;
                &-main {
                        padding: 0 10px;
                        @include respond(normal){
                                flex-wrap: wrap;
                                flex-direction: column;
                                @include flex-center;
                        }

                }

                width: 100%;
                position: relative;
                z-index: 2;

                &-img {
                        @include respond(normal){
                                margin-top: rem(10px);
                        }
                        img {
                                border-radius: 50%;
                                width: rem(270px);
                                height: rem(270px);
                                object-fit: cover;
                                box-shadow: 0 0 20px #606060b8;
                                @include respond(min-mobile){
                                        width: rem(200px);
                                        height: rem(200px);    
                                }
                        }
                }

                &-content {

                        .txt {
                                &-tiny {
                                        font-size: rem(30px);
                                        font-family: 'Assistant', sans-serif;
                                        font-weight: 200;
                                        color: #e4e4e4;
                                }

                                &-name {
                                        color: $color-white;
                                        font-family: 'Rubik', sans-serif;
                                        font-size: rem(40px);
                                        margin-bottom: rem(10px);
                                }

                                &-role {
                                        color: #b6bdbdbd;
                                        font-family: 'Nunito', sans-serif;
                                        font-weight: 600;
                                        font-size: rem(50px);
                                        letter-spacing: rem(5px);
                                        transform: skewX(340deg);
                                        margin-bottom: rem(10px);
                                        background-color: #1f1f1f;
                                        padding: 0 6px;
                                        width: max-content;
                                        @include respond(min-narrow){
                                                font-size: 30px;
                                        }
                                        @include respond(max-mobile){
                                                font-size: 25px;
                                        }
                                        @include respond(xs-mobile){
                                                font-size: 20px;
                                        }
                                }

                                &-paragraph {
                                        line-height: 1.5;
                                        @include respond(min-narrow){
                                                font-size: 12px;
                                        }
                                }
                        }
                }
        }
}