@import "./src/styles/setup";

.header {
  background-color: $color-black;
  color: $color-white;
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 9999;
  box-shadow: 1px 0px 16px 1px #000000cc;
  .link-logo{
    color: white;
  }
 nav {
 height: 100%;
 align-items: center;
 font-family: 'Montserrat', sans-serif;
  

  .nav-menu {
    @include respond(max-narrow){
      background-color: $color-black;
     position: fixed;
     top: 0;
     right: 0;
     bottom: 0;
     display: none;
     flex-direction: column;
     padding: 0 15px;
     z-index: 99;
     width: 160px;
     animation: slideInFromRight 1s ease-out;
     @include flex-center;
    

      &.open-nav{
        display: flex;
      }
  }
     
    }
          >*:not(:last-child){
            margin-right: 10px;
            @include respond(max-narrow){
            margin-right: 0;
            margin-bottom: 10px;

            }
          }
        .nav-link{
          @include respond(max-narrow){
            text-align: center;
            &:not(:last-child){
              margin-bottom: 30px;
            }
          }
          a{
              color:$color-white; 
              letter-spacing: 1px; 
              font-size: rem(18px);
              transition: all .3s ease-in-out;  
              padding: 0 6px;
              padding-bottom: 17px;

              @include respond(max-narrow){
                display: inline-block;
               padding: 10px;
               width: 100px;
               background-color: $color-white;
               color:$color-black;
              }
              &:hover{
                      color: $color-light-grey-3;
                      @include respond(max-narrow){
                      background-color:rgba($color-light-grey,0.2);
                      color: $color-light-white;}
              }
                &.active{
                        color:$color-light-grey;
                    
                        border-bottom: 2px solid $color-white;
                        @include respond(max-narrow){
                          border-bottom: none;
                          font-weight: 400;
                          background-color:rgba($color-light-grey,0.2);
                          color: $color-light-white;
                          border-bottom:1px solid $color-white ;
                        }
                }
          }      
        }
  }
 }

 .hamburger{
  position: relative;
  color: $color-white;
  font-size: rem(30px) !important;
  z-index: 100;
  display: none;
  margin-right: 20px !important;
  cursor: pointer;
  @include respond(max-narrow){
    display: block;
  }
}
