
        @import "./src/styles/setup";
        .contact {
                background-color: #43434380;
                width: fit-content;
                margin: 0 auto;
                border-radius: 30px;
                padding: 21px;
                transition: transform .3s;
                &:hover{
                        transform: translateY(-5px);
                }
                &-container{
                        p{
                                font-family: 'Assistant', sans-serif;
                                font-weight: 300;
                                
                                @include respond(min-narrow){
                                        font-size: 12px;
                                }
                                max-width: 400px;
                                line-height: 1.4;
                        }
                }
        }