
        @import "./src/styles/setup";
        .loader-container {
                min-height:100%;
                .loader-cmp {
                  border: 5px solid #f3f3f3;
                  border-radius: 50%;
                  border-top: 5px solid $color-black-light;
                  width: 50px;
                  height: 50px;
                  animation: spin 2s linear infinite;
                }
              }
              //animation
              @keyframes spin {
                0% {
                  transform: rotate(0deg);
                }
              
                100% {
                  transform: rotate(360deg);
                }
              }
              